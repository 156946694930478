import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword, selectForgotPasswordStatus, selectLoginError, selectLoginStatus, updateLoginStatus } from "../reducerSlices/accountsSlice";
import { isFormValid } from "../../../component/Controls/services/formService";
import { accountService } from "../services/accountService";
import { fetchStatus, masterTenantId, tenantId } from "../../../api/client";
import EmailText from "../../../component/Controls/custom/textbox/emailText";
import PasswordText from "../../../component/Controls/custom/textbox/passwordText";
import '../css/login.css';
import hirebaseLogoImg from '../../../assets/images/logo/hirebase-logo.svg';
import backgroundImg from '../../../assets/images/login/dashboards-metrics.svg';
import facebookImg from "../../../assets/images/clipart/facebook.svg";
import facebookImg2 from "../../../assets/images/clipart/facebook-2.svg";
import linkedinImg from "../../../assets/images/clipart/linkedin.svg";
import linkedinImg2 from "../../../assets/images/clipart/linkedin-2.svg";
import twitterImg from "../../../assets/images/clipart/twitter.svg";
import twitterImg2 from "../../../assets/images/clipart/twitter-2.svg";
import EllipsisLoader from "../../../component/Controls/loaders/ellipsisLoader";
import CancelButton from "../../../component/Controls/custom/buttons/cancelButton";
import SaveButton from "../../../component/Controls/custom/buttons/saveButton";


const Login = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const LOGIN_FORM = "LoginForm";

    const loginStatus = useSelector(selectLoginStatus);
    const loginError = useSelector(selectLoginError);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [lfSubmitted, setLFSubmitted] = useState(false);

    const [twitterImage, setTwitterImage] = useState(twitterImg);
    const [linkedinImage, setLinkedinImage] = useState(linkedinImg);
    const [facebookImage, setFacebookImage] = useState(facebookImg);

    const onLoginClicked = (e) => {
        e.preventDefault();
        if (isFormValid(LOGIN_FORM)) {
            accountService.login({ client: tenantId, email: email, password: password, authenticationType: "TOKEN" });
        }
        else {
            setLFSubmitted(true);
        }
    }

    useEffect(() => {
        if (loginStatus === fetchStatus.SUCCEEDED) {
            if (accountService.isAuthenticated()) {
                dispatch(updateLoginStatus());
                //const { returnUrl } = props.location.state || { returnUrl: { pathname: "/" } };
                // if (accountService.isAdmin() || accountService.isPayroll() || accountService.isReviewer()) {
                //     if (accountService.isAdmin()) {
                //         history.push("/people");
                //     }
                //     else {
                //         history.push("/admin");
                //     }
                // }
                // else {
                //     history.push("/");
                // }

                if (accountService.isApplicant()) {
                    accountService.logout();
                    history.push("/register");
                }
                if (accountService.isSalesRep()) {
                    history.push("/lead");
                }
                else {
                    if (accountService.isAdmin() || accountService.isPayroll() || accountService.isReviewer() || accountService.isSalesManager()) {
                        if (accountService.isAdmin() || accountService.isSalesManager()) {
                            history.push("/dashboard");
                        }
                        else if (accountService.isPayroll() || accountService.isReviewer()) {
                            history.push("/people");
                        }
                    }
                    else {
                        history.push("/register");
                    }
                }
            }
        }
    }, [loginStatus, dispatch, history]);
    useEffect(() => {
        if (loginStatus === fetchStatus.FAILED) {

        }
    }, [loginStatus]);


    const [show, setShow] = useState(false);
    const [emailForgot, setEmailForgot] = useState('');
    const [emailInvalid, setEmailInvalid] = useState(false);

    const [fpfSubmitted, setFPFSubmitted] = useState(false);
    const FORGOT_PASSWORD_FORM = "ForgotPasswordForm";

    const forgotPasswordStatus = useSelector(selectForgotPasswordStatus);

    const onCloseClicked = () => {
        setShow(false);
        setEmailForgot('');
        setEmailInvalid(false);
    };

    const handleShow = () => setShow(true);

    const onForgotPassword = (e) => {
        e.preventDefault();
        if (isFormValid(FORGOT_PASSWORD_FORM)) {
            dispatch(forgotPassword(
                { Client: tenantId, email: emailForgot }
            ));
        }
        else {
            setFPFSubmitted(true);
        }
    }


    return (
        <>
            <div className="container-fluid">
                <div className="row no-gutter">
                    <div className="col-md-6 d-none d-md-inline bg-image">
                        <div className="login d-flex">
                            <div className="container login-container">
                                <div className="row">
                                    <div className="col-lg-11 col-xl-11 mx-auto">
                                        <div className="text-center">
                                            <h1>HireBase Dashboards</h1>
                                            <p>Welcome to the hirebase portal</p>
                                        </div>
                                        <img src={backgroundImg} className="w-100" />
                                    </div>
                                </div>
                                <footer className="footer pt-5 text-end">
                                    {/* <div className="pt-md-3"> */}
                                    <a href="https://twitter.com/base_hire"
                                        target="_balcnk"
                                        onMouseEnter={() => setTwitterImage(twitterImg2)}
                                        onMouseLeave={() => setTwitterImage(twitterImg)}
                                    >
                                        <img src={twitterImage} alt="no" />
                                    </a>
                                    <a href="https://www.linkedin.com/company/hirebase-co/about/"
                                        target="_balcnk"
                                        onMouseEnter={() => setLinkedinImage(linkedinImg2)}
                                        onMouseLeave={() => setLinkedinImage(linkedinImg)}
                                        className="ms-3"
                                    >
                                        <img src={linkedinImage} alt="no" />
                                    </a>
                                    <a href="https://www.facebook.com/gethirebase"
                                        target="_balcnk"
                                        onMouseEnter={() => setFacebookImage(facebookImg2)}
                                        onMouseLeave={() => setFacebookImage(facebookImg)}
                                        className="ms-3"
                                    >
                                        <img src={facebookImage} alt="no" />
                                    </a>
                                    {/* </div> */}
                                </footer>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 bg-light">
                        {/* <div className="login d-flex align-items-center py-5"> */}
                        <div className="login d-flex">
                            <div className="container  login-container">
                                <div className="row">
                                    <div className="col-lg-10 col-xl-7 mx-auto">
                                        <img src={hirebaseLogoImg} alt="hirebase logo" className="mx-auto d-block w-75" />
                                        <p className="login-text mb-4">Login</p>
                                        <p className="text-muted mb-4">Enter your credentials to access your account.</p>
                                        <label className="field-label">Email</label>
                                        <EmailText
                                            id="txtEmail"
                                            name="txtEmail"
                                            EmailTextClasses="form-control p-2"
                                            placeholder="Email"
                                            required={true}
                                            errorMessage="Please enter email"
                                            form={LOGIN_FORM}
                                            submited={lfSubmitted}
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)} />
                                        <label className="field-label mt-4">Password</label>
                                        <PasswordText
                                            id="txtPassword"
                                            name="txtPassword"
                                            PasswordTextClasses="form-control p-2"
                                            placeholder="Password"
                                            required={true}
                                            errorMessage="Please enter password"
                                            form={LOGIN_FORM}
                                            submited={lfSubmitted}
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)} />
                                        <button
                                            type="submit"
                                            className="col-md-12 btn btn-login pt-2 mt-4"
                                            disabled={loginStatus === fetchStatus.LOADING}
                                            onClick={(e) => onLoginClicked(e)}>
                                            {loginStatus === fetchStatus.LOADING ? <i className="fa fa-circle-notch fa-spin fa-fw me-2"></i> : <></>}Login</button>
                                        <div className="custom-control custom-checkbox mb-3 mt-2">
                                            <label
                                                htmlFor="forgotPassword"
                                                className="custom-control-label"
                                                onClick={handleShow}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                Forgot Password?
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5 mt-md-0   mt-lg-5">
                                    <div className="col-lg-10 col-xl-7 mx-auto text-center">
                                        Need access ?  <span className="text-pink"><Link to="/register" className="text-pink">Register</Link></span>
                                    </div>
                                </div>
                                {/* <div className="col-lg-10 col-xl-7 mx-auto"> */}
                                <footer className="footer mt-5 pt-5 mt-md-0  mt-lg-5 text-center">
                                    <span className="text-muted">Copyright © 2022 - Hirebase | All Rights Reserved.</span>
                                </footer>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {show && (
                <div
                    className="modal fade show"
                    style={{ display: 'block' }}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Forgot Password</h5>
                                <button
                                    type="button"
                                    className="modal-close-button ms-auto"
                                    onClick={onCloseClicked}
                                >
                                    <span aria-hidden="true"><i className="fa fa-times" aria-hidden="true"></i></span>
                                </button>
                            </div>
                            <div className="modal-body">

                                {forgotPasswordStatus === fetchStatus.SUCCEEDED ?
                                    <>
                                        <p>Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.</p>
                                    </>
                                    :
                                    <>
                                        <form>
                                            <div className="form-group">
                                                <label className="field-label">What's your email?</label>
                                                <EmailText
                                                    id="txtEmailForgot"
                                                    name="txtEmailForgot"
                                                    EmailTextClasses="form-control p-2"
                                                    placeholder="Email"
                                                    required={true}
                                                    errorMessage="Please enter email"
                                                    value={emailForgot}
                                                    onChange={(e) => {
                                                        setEmailForgot(e.target.value);
                                                        setEmailInvalid(false);
                                                    }}
                                                    form={FORGOT_PASSWORD_FORM}
                                                    submited={fpfSubmitted}
                                                />
                                                {emailInvalid && (
                                                    <span className="error-label text-danger">
                                                        <i className="fa fa-exclamation-circle me-1" aria-hidden="true"></i>
                                                        Email field cannot be blank
                                                    </span>
                                                )}
                                            </div>
                                        </form>
                                    </>
                                }

                            </div>
                            <div className="modal-footer">
                                {(forgotPasswordStatus === fetchStatus.LOADING) ? <EllipsisLoader /> : <>
                                    <>
                                        <CancelButton
                                            CancelButtonClasses="mr-2"
                                            text={forgotPasswordStatus === fetchStatus.SUCCEEDED && 'Close'}
                                            onCancelClicked={() => onCloseClicked()} />
                                        {forgotPasswordStatus !== fetchStatus.SUCCEEDED &&
                                            <SaveButton
                                                toolTipText="Reset Password"
                                                text="Send password reset email"
                                                onSaveClicked={(e) => onForgotPassword(e)} />
                                        }
                                    </>

                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {show && <div className="modal-backdrop fade show"></div>}
        </>
    )
}

export default Login;