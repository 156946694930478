import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import { Filter, GridComponent, Inject, Page, Sort, Resize, Toolbar, CommandColumn } from '@syncfusion/ej2-react-grids';

import { fetchStatus, tenantId } from '../../../api/client';
import { isFormValid } from '../../../component/Controls/services/formService';
import { isObjectArray } from '../../../utilities/utilityFunctions';
import { applicantStatuses, userStatuses } from '../../shared/services/masterData';

import InputGroup from '../../../component/Controls/custom/inputGroup/inputGroup'
import ShortText from '../../../component/Controls/custom/textbox/shortText'
import Selector from '../../../component/Controls/custom/selectors/selectors';
import ContinueButton from '../../../component/Controls/buttons/continueButton';
import BackButton from '../../../component/Controls/buttons/backButton';
import EllipsisLoader from '../../../component/Controls/loaders/ellipsisLoader';

import SetupProgress from '../../../component/Progress/SetupProgress';
import { createNewStatus, createStatus, getAllStatuses, getStatusById, removeStatus, selectAllStatuses, selectCreationStatus, selectModificationStatus, selectRemovalStatus, selectSingleStatus, selectStatus, selectStatusById, selectStatusForm, selectStatusFormStatus, updateSingleData, updateStatus, updateStatuses } from '../../setting/reducerSlices/statusesSlice';
import { onValueChanged, sections } from '../../setting/services/statusSaver';
import { Operation } from '../../../common/constants';
import GridLoader from '../../../component/Controls/loaders/gridLoader';
import { show } from '../../../component/Controls/alerts/reducerSlices/confirmAlertSlice';
import { messages } from '../../../common/actionMessages';
import ReactTooltip from 'react-tooltip';


function CompanyStatuses() {

  const dispatch = useDispatch();
  const history = useHistory();

  const STATUS_FORM = "Statusform";

  const [createMode, setCreateMode] = useState(false);
  const [editMode, setEditmode] = useState(false);
  const [cFInvalid, setCFInvalid] = useState(false);
  const [cfSubmitted, setCFSubmitted] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]);


  const statusFormStatus = useSelector(selectStatusFormStatus);
  const statusForm = useSelector(selectStatusForm);

  const status = useSelector(selectStatusById);
  const statusRequestStatus = useSelector(selectSingleStatus);
  const statusCreationStatus = useSelector(selectCreationStatus);
  const statusModificationStatus = useSelector(selectModificationStatus);
  const statusesRemovalStatus = useSelector(selectRemovalStatus);
  const statuses = useSelector(selectAllStatuses);
  const statusesRequestStatus = useSelector(selectStatus);


  const onAddRecordClicked = (e) => {
    dispatch(createNewStatus());

    setCreateMode(true);
    setEditmode(false);
    setCFSubmitted(false);
  };



  useEffect(() => {
    if (statusesRequestStatus === fetchStatus.IDLE) {
      _loadStatuses();
    }
  }, [statusesRequestStatus, dispatch]);

  useEffect(() => {
    if (statusCreationStatus === fetchStatus.SUCCEEDED) {
      _loadStatuses();
    }
  }, [statusCreationStatus]);

  useEffect(() => {
    if (statusModificationStatus === fetchStatus.SUCCEEDED) {
      _loadStatuses();
    }
  }, [statusModificationStatus]);

  let _dummyCards = [];

  useEffect(() => {
    if (statusesRequestStatus === fetchStatus.SUCCEEDED) {
      dispatch(updateStatus());
    }
  }, [statusesRequestStatus]);

  useEffect(() => {
    if (statusesRemovalStatus === fetchStatus.SUCCEEDED) {
        _loadStatuses();
    }
}, [statusesRemovalStatus]);

  const _loadStatuses = async () => {
    await dispatch(getAllStatuses({
      page: 0,
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETSTATUSES",
        payload: {
          id: "",
          data: JSON.stringify({
            // offset: offset,
            // limit: 1000,
            // where: whereClause
          })
        }
      }
    }));
  }

  const _loadStatus = async (id, page) => {
    await dispatch(getStatusById({
      page: page,
      model: {
        client: tenantId,
        serviceType: "TENANTADMIN",
        operation: "GETSTATUSBYID",
        payload: {
          id: id,
          data: ""
        }
      }
    }));
  }

  const onStatusSelected = (e, id) => {
    e.preventDefault();
    _loadStatus(id, undefined);
    setCreateMode(false);
    setEditmode(true);
  };


  const onDeleteClicked = (e, id) => {
    e.preventDefault();
    if (id) {
      dispatch(show({
        id: id,
        title: messages.confirmAlert.title,
        content: messages.confirmAlert.content.removal('Status'),
        confirmedCallback: _removeStatus
      }));
    }
  };



  const _removeStatus = async (id) => {
    await dispatch(removeStatus({
      client: tenantId,
      serviceType: "TENANTADMIN",
      operation: "DELETESTATUS",
      payload: {
        id: id,
        data: ""
      }
    }))
  };



  const _geStatusText2 = (statuses, id) => {
    if (isObjectArray(statuses)) {
      let _statuse = statuses.find(l => l.value === id);
      if (_statuse && _statuse.text) {
        return _statuse.text;
      }
    }
    return "";
  }

  const getStatus = (field, data, column) => {
    // return _geStatusText(statuses, data["PrimaryStatus"]);
    return _geStatusText2(applicantStatuses, data["PrimaryStatus"]);
  }
  const addActionTemplate = (props) => {
    return <>
      <i className='fa fa-pencil' data-tip='Edit' title='Edit' onClick={(e) => onStatusSelected(e, props.id)}></i>
      {props.isDefaultStatusName
        ?
        <></>
        :
        <i className='fa fa-trash ps-3' data-tip='Delete' title='Delete' onClick={(e) => onDeleteClicked(e, props ? props.id : "")}></i>
      }
      <ReactTooltip place="bottom" type="info" effect="solid" className="c-tooltip" arrowColor='#0a253e' />
    </>
  }

  const gridRef = useRef(null);

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.refresh();
    }
  }, [statuses, statusesRequestStatus]);

  const columns = [
    { field: 'StatusName', headerText: 'Status Name', width: '100' },
    { field: 'PrimaryStatus', headerText: 'Primary Status', width: '100', valueAccessor: getStatus },
    { field: 'Status', headerText: 'Status', width: '100' },
    {
      headerText: 'Action',
      template: addActionTemplate,
      width: '50px',
    },
  ];

  const onValueUpdated = (e, section, value) => {
    let _statusObj = Object.assign({}, status);
    let _newStatusObj = onValueChanged(_statusObj, e, section, value);
    dispatch(updateSingleData({
      status: _newStatusObj,
      operation: Operation.UPDATED
    }));
  };

  let _statusOptions;
  useEffect(() => {
    if (isObjectArray(userStatuses) && userStatuses.length > 0) {
      _statusOptions = userStatuses.map((status, index) => (
        <option key={nanoid()} value={status.value}>
          {status.text}
        </option>
      ));
      setStatusOptions(_statusOptions);
    }
  }, [userStatuses]);

  const onSaveClicked = (e, id, created) => {
    e.preventDefault();
    if (isFormValid(STATUS_FORM)) {
      if (status) {
        if (created) {
          dispatch(createStatus({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "SAVESTATUS",
            payload: {
              id: id,
              data: JSON.stringify({
                status: JSON.stringify(status),
              })
            }
          }));
        }
        else {
          dispatch(updateStatuses({
            client: tenantId,
            serviceType: "TENANTADMIN",
            operation: "SAVESTATUS",
            payload: {
              id: id,
              data: JSON.stringify({
                status: JSON.stringify(status),
              })
            }
          }));
        }
      }
      setCreateMode(false);
      setEditmode(false);
      setCFInvalid(false);
      setCFSubmitted(false);
    }
    else {
      setCFInvalid(true);
    }
    setCFSubmitted(true);
  };

  const onCancelClicked = (e) => {
    setCreateMode(false);
    setEditmode(false);
    setCFInvalid(false);
    setCFSubmitted(false);
  };

  const onNextClicked = (e) => {
    // history.push('companyExperience');
  }

  const onBack = (e) => {
    history.push('companyExperience');
  }

  return (
    <div className="container ">
      <div className="row">
        <div className="col-md-4 card setup-wizard-left-side d-flex align-items-stretch p-0">
          <SetupProgress percentage="30" getPercentage="45" subTitle="Statuses" currentPage="companyStatuses" />
        </div>
        <div className="col-md-8 card setup-wizard-right-side d-flex align-items-stretch p-0">
          <div className="card-body mb-5 mb-md-0 animate__animated animate__fadeIn">

            {(statusesRequestStatus === fetchStatus.LOADING ||
              statusRequestStatus === fetchStatus.LOADING ||
              statusesRemovalStatus === fetchStatus.LOADING) &&
              <div className="setup-wizard-loader">
                <GridLoader />
              </div>
            }

            <div className="row p-2">
              <div className="col-md-12">
                <div className="card-left-header">Statuses Configuration</div>
                <div className="card-left-sub-header">Setup custom statuses sets tailored to organizational proficiency needs.</div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                {/* <span className="detail-container-title">Skills Group</span> */}
              </div>
              <div className="col-md-6 text-end">
                {(createMode || editMode) ?
                  <>
                    <button className="c-btn dark-btn" type="button" title='Cancle' onClick={(e) => onCancelClicked(e)}>
                      <i className="fa fa-times"></i>
                    </button>
                  </>
                  :
                  <button className="c-btn dark-btn" type="button" title='Create new skill group' onClick={(e) => onAddRecordClicked(e)}>
                    <i className="fa fa-plus"></i>
                  </button>
                }
              </div>
            </div>

            {!editMode && !createMode ?
              <div className="row p-2">
                <GridComponent ref={gridRef} dataSource={statuses} allowPaging={true} allowSorting={true} allowResizing={true} width="100%" toolbar={['Search']} pageSettings={{ pageSize: 7, pageCount: 5 }} rowHeight={50} columns={columns} >
                  <Inject services={[Page, Sort, Filter, Resize, Toolbar, CommandColumn]} />
                </GridComponent>
              </div>
              :
              <div className="row p-2">
                <div className="col-md-6">
                  <div className="form-group p-0 pt-2">
                    <label id="lblFirstName" className="flyout-label" htmlFor="txtStatusNumber">Status Name</label>
                    {!editMode && !createMode ?
                      <label className="field-label-value">{status && status.StatusName ? status.StatusName.Data : ""}</label> :
                      <InputGroup
                        icon={<i className="fa fa-address-card"></i>}
                        control={
                          <ShortText
                            id="txtStatusName"
                            name="txtStatusName"
                            ShortTextClasses="form-control"
                            placeholder="Status Name"
                            value={status && status.StatusName ? status.StatusName.Data : ""}
                            onChange={(e) => onValueUpdated(e, sections.STATUS_DETAILS, null)}
                            // required={statusForm && statusForm.StatusName.Required}
                            required={true}
                            errorMessage={statusForm && statusForm.StatusName.Validation}
                            form={STATUS_FORM}
                            submited={cfSubmitted} />
                        }
                      />
                    }
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group p-0 pt-2">
                    <label id="lblState" className="flyout-label" htmlFor="drpPrimaryStatus">Primary State</label>
                    {!editMode && !createMode ?
                      <label className="field-label-value">{status && status.PrimaryStatus ? status.PrimaryStatus.Data : ""}</label> :
                      <InputGroup
                        icon={<i className="fa fa-address-card"></i>}
                        control={
                          <Selector
                            // required={statusForm && statusForm.PrimaryStatus.Required}
                            required={true}
                            disabled={status && status.isDefaultStatusName}
                            errorMessage={statusForm && statusForm.PrimaryStatus.validation}
                            form={STATUS_FORM} submited={cfSubmitted}
                            id="drpPrimaryStatus"
                            name="drpPrimaryStatus"
                            SelectorClasses="form-select"
                            value={status && status.PrimaryStatus ? status.PrimaryStatus.Data.trim() : ""}
                            options={
                              <>
                                {applicantStatuses.map((status) => <option value={status.value}>{status.text}</option>)}
                              </>
                            } onChange={(e) => onValueUpdated(e, sections.STATUS_DETAILS, null)} />
                        }
                      />
                    }
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group p-0 pt-2">
                    <label id="lblStatus" className="flyout-label" htmlFor="drpStatus">Status</label>
                    {!editMode && !createMode ?
                      <label className="field-label-value">{status && status.Status ? status.Status.Data : ""}</label> :
                      <InputGroup
                        icon={<i className="fa fa-address-card"></i>}
                        control={
                          <Selector
                            required={true}
                            // required={statusForm && statusForm.Status.Required}
                            errorMessage={statusForm && statusForm.Status.validation}
                            form={STATUS_FORM}
                            submited={cfSubmitted}
                            id="drpStatus"
                            name="drpStatus"
                            SelectorClasses="form-select"
                            value={status && status.Status ? status.Status.Data.trim() : ""}
                            options={
                              <>
                                {statusOptions}
                              </>
                            }
                            onChange={(e) => onValueUpdated(e, sections.STATUS_DETAILS, null)} />
                        }
                      />
                    }
                  </div>
                </div>
              </div>

            }


          </div>
          <div className="card-footer bottom-0 px-4 py-2 w-100">
            <div className="row">
              <div className="col-md-12">
                {statusCreationStatus === fetchStatus.LOADING || statusModificationStatus === fetchStatus.LOADING
                  ? <div className="float-end my-2"><EllipsisLoader /></div>
                  :
                  <>
                    {(createMode || editMode) ?
                      <>
                        <BackButton onBackClicked={(e) => onCancelClicked(e)} text="Cancle" />
                        <div className="float-end">
                          <ContinueButton onClick={(e) => onSaveClicked(e, status ? status.id : "", createMode)} text="Save" />
                        </div>
                      </>
                      :
                      <>
                        <BackButton onBackClicked={(e) => onBack(e)} />
                        <div className="float-end">
                          <ContinueButton onClick={(e) => onNextClicked(e)} />
                        </div>
                      </>
                    }
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyStatuses;