// import { ErrorSource, USER_KEY } from "./constants";
import { USER_KEY } from "./constants";

//import store from '../store';
//import { showToast } from "../components/Controls/toasts/reducerSlices/toastSlice";

const getToken = () => {
    let user;
    if (localStorage.getItem(USER_KEY)) {
        user = JSON.parse(localStorage.getItem(USER_KEY));
    }
    // console.log('getToken  user--->', user.jwtToken);
    return user ? `Bearer fake-jwt-token ${user.jwtToken}` : "";
}

export const getAxiosRequestConfig = () => {
    // console.log('getAxiosRequestConfig call-->');
    return {
        headers: {
            'Authorization': getToken()
        }
    }
}

export const documentTypes = [{ value: 1, text: "I9" }, { value: 2, text: "W4" }, { value: 3, text: "Sexual Harassments Certificate" }, { value: 4, text: "Terms" }, { value: 5, text: "Others" }, { value: 6, text: "EDD" }, { value: 7, text: "Safety Test and Certificate" }, { value: 8, text: "Covid Form" }, { value: 9, text: "W4 + EDD" }, { value: 10, text: "Vaccination Details" }];

export const documentTypesupload = [{ value: 1, text: "I9" }, { value: 3, text: "Sexual Harassments Certificate" }, { value: 4, text: "Terms" }, { value: 5, text: "Others" }, { value: 7, text: "Safety Test and Certificate" }, { value: 8, text: "Covid Form" }, { value: 9, text: "W4 + EDD" }, { value: 10, text: "Vaccination Details" }];

// export const atob = (inputString) => {
//     return Buffer.from(inputString, 'binary').toString('base64');
// };

// export const btoa = (inputString) => {
//     return Buffer.from(inputString, 'base64').toString('binary');
// };

export const getBase64Image = file => {
    return new Promise(resolve => {
        let fileInfo;
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);

        // on reader load somthing...
        reader.onload = () => {
            // Make a fileInfo Object
            console.log("Called", reader);
            baseURL = reader.result;
            console.log(baseURL);
            resolve(baseURL);
        };
        console.log(fileInfo);
    });
};

export const downloadFile = (file, fileName) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = file;
    downloadLink.download = fileName;
    downloadLink.click();
}

export const isAgeOver18 = (dateOfBirthString) => {
    var currentDate = new Date();
    var dateOfBirth = new Date(dateOfBirthString);

    if (!!dateOfBirth && !!currentDate) {
        var age = currentDate.getFullYear() - dateOfBirth.getFullYear();
        if (currentDate.getMonth() > dateOfBirth.getMonth()) {
            age++;
        }
        if (currentDate.getMonth() == dateOfBirth.getMonth()) {
            if (currentDate.getDate() >= dateOfBirth.getDate()) {
                age++;
            }
        }
        return (age >= 18);
    }
}

export const openFileUploader = (id) => {
    let fileUploader = document.getElementById(id);
    fileUploader.click();
};

export const setUploadedPhoto = (e, id) => {
    if (e.target.files && e.target.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
            document.getElementById(id).src = e.target.result;
        };
        reader.readAsDataURL(e.target.files[0]);
    }
};

export const handlerError = (errors) => {
    // store.dispatch(showToast({
    //     title: undefined,
    //     content: errors,
    //     autoHide: true,
    // }))
};

export const formatraw = (date, format, utc) => {

    let raw = new Date(date.split(" ").join("T"));

    let res = format.slice();

    const MMMM = ["\x00", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const MMM = ["\x01", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const dddd = ["\x02", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const ddd = ["\x03", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    function ii(i, len) {
        let s = i + "";
        len = len || 2;
        while (s.length < len) s = "0" + s;
        return s;
    }

    const y = utc ? raw.getUTCFullYear() : raw.getFullYear();
    res = res.replace(/(^|[^\\])yyyy+/g, "$1" + y);
    res = res.replace(/(^|[^\\])yy/g, "$1" + y.toString().substr(2, 2));
    res = res.replace(/(^|[^\\])y/g, "$1" + y);

    const M = (utc ? raw.getUTCMonth() : raw.getMonth()) + 1;
    res = res.replace(/(^|[^\\])MMMM+/g, "$1" + MMMM[0]);
    res = res.replace(/(^|[^\\])MMM/g, "$1" + MMM[0]);
    res = res.replace(/(^|[^\\])MM/g, "$1" + ii(M));
    res = res.replace(/(^|[^\\])M/g, "$1" + M);

    const d = utc ? raw.getUTCraw() : raw.getDate();
    res = res.replace(/(^|[^\\])dddd+/g, "$1" + dddd[0]);
    res = res.replace(/(^|[^\\])ddd/g, "$1" + ddd[0]);
    res = res.replace(/(^|[^\\])dd/g, "$1" + ii(d));
    res = res.replace(/(^|[^\\])d/g, "$1" + d);

    const H = utc ? raw.getUTCHours() : raw.getHours();
    res = res.replace(/(^|[^\\])HH+/g, "$1" + ii(H));
    res = res.replace(/(^|[^\\])H/g, "$1" + H);

    const h = H > 12 ? H - 12 : H == 0 ? 12 : H;
    res = res.replace(/(^|[^\\])hh+/g, "$1" + ii(h));
    res = res.replace(/(^|[^\\])h/g, "$1" + h);

    const m = utc ? raw.getUTCMinutes() : raw.getMinutes();
    res = res.replace(/(^|[^\\])mm+/g, "$1" + ii(m));
    res = res.replace(/(^|[^\\])m/g, "$1" + m);

    const s = utc ? raw.getUTCSeconds() : raw.getSeconds();
    res = res.replace(/(^|[^\\])ss+/g, "$1" + ii(s));
    res = res.replace(/(^|[^\\])s/g, "$1" + s);

    let f = utc ? raw.getUTCMilliseconds() : raw.getMilliseconds();
    res = res.replace(/(^|[^\\])fff+/g, "$1" + ii(f, 3));
    f = Math.round(f / 10);
    res = res.replace(/(^|[^\\])ff/g, "$1" + ii(f));
    f = Math.round(f / 10);
    res = res.replace(/(^|[^\\])f/g, "$1" + f);

    const T = H < 12 ? "AM" : "PM";
    res = res.replace(/(^|[^\\]){TT}/g, "$1" + T);
    res = res.replace(/(^|[^\\]){T}/g, "$1" + T.charAt(0));

    const t = T.toLowerCase();
    res = res.replace(/(^|[^\\]){tt}/g, "$1" + t);
    res = res.replace(/(^|[^\\]){t}/g, "$1" + t.charAt(0));

    let tz = -raw.getTimezoneOffset();
    let K = utc || !tz ? "Z" : tz > 0 ? "+" : "-";
    if (!utc) {
        tz = Math.abs(tz);
        let tzHrs = Math.floor(tz / 60);
        let tzMin = tz % 60;
        K += ii(tzHrs) + ":" + ii(tzMin);
    }

    res = res.replace(/(^|[^\\])K/g, "$1" + K);

    const day = (utc ? raw.getUTCDay() : raw.getDay()) + 1;
    res = res.replace(new RegExp(dddd[0], "g"), dddd[day]);
    res = res.replace(new RegExp(ddd[0], "g"), ddd[day]);

    res = res.replace(new RegExp(MMMM[0], "g"), MMMM[M]);
    res = res.replace(new RegExp(MMM[0], "g"), MMM[M]);

    res = res.replace(/\\(.)/g, "$1");

    return res;
}


const isObjectArray = (object) => {
    return object && Array.isArray(object);
}

export const isValueObject = (value) => {
    return (typeof value === 'object' && value !== null)
}

export const toLocalDate = (utcDateString) => {
    try {
        let date = new Date(utcDateString);
        return date.toLocaleString();
    }
    catch (error) {
        return "";
    }
};

export const toLocalDateMinute = (utcDateString) => {
    try {
        let date = new Date(utcDateString);
        // return date.toLocaleDateString() + ' ' + date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });

    }
    catch (error) {
        return "";
    }
};

export const toLocalDateOnly = (utcDateString) => {
    try {
        let date = new Date(utcDateString);
        return date.toLocaleDateString();
    }
    catch (error) {
        return "";
    }
};

export const _getDateText = (date) => {
    if (date) {
        let dateParts = date.split('-');
        return dateParts && dateParts.length === 3 ? `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}` : "";
    }
    return "";
}

export const _getLocationText = (locations, id) => {
    if (isObjectArray(locations)) {
        let loc = locations.find(l => l.value === id);
        if (loc && loc.text) {
            return loc.text;
        }
    }
    return "";
}

export const _getLocationTextAll = (locations, id) => {
    if (isObjectArray(locations)) {
        if (id == "All") {
            return "All";
        }
        let loc = locations.find(l => l.value === id);
        if (loc && loc.text) {
            return loc.text;
        }
    }
    return "";
}

export const _getContent = (content, logValue) => {
    let _content = content;
    if (logValue === -2) {
        let contentParts = content.split('###');
        if (isObjectArray(contentParts) && contentParts.length === 3) {
            _content = contentParts[0];
            _content += toLocalDate(contentParts[1]);
            _content += contentParts[2];
        }
    }
    return _content;
};

export const getRandomNumber = () => {
    return Math.floor(100000 + Math.random() * 900000);
}

export const toInteger = (value) => {
    if (/^[-+]?(\d+|Infinity)$/.test(value)) {
        return Number(value);
    } else {
        return 0;
    }
};


export const getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const getInitials = (name) => {
    let initials;
    const nameSplit = name.split(" ");
    const nameLength = nameSplit.length;
    if (nameLength > 1) {
        initials =
            nameSplit[0].substring(0, 1) +
            nameSplit[nameLength - 1].substring(0, 1);
    } else if (nameLength === 1) {
        initials = nameSplit[0].substring(0, 1);
    } else return;

    return initials.toUpperCase();
};
export const createImageFromInitials = (size, name, color) => {
    if (name == null) return;
    name = getInitials(name)

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.width = canvas.height = size

    context.fillStyle = "#ffffff"
    context.fillRect(0, 0, size, size)

    context.fillStyle = `${color}50`
    context.fillRect(0, 0, size, size)

    context.fillStyle = color;
    context.textBaseline = 'middle'
    context.textAlign = 'center'
    context.font = `${size / 2}px Roboto`
    context.fillText(name, (size / 2), (size / 2))

    return canvas.toDataURL()
};

export const ssnShortFormate = (string) => {
    if (string.length > 4) {
        return 'XXX-XX-' + string.substring(string.length - 4)
    } else {
        return string;
    }
}

export const _getNoDataText = () => {
    return 'None';
}

export const getFileSizeText = (length) => {
    if (length > 0) {
        if (length >= 1048576) {
            return (length / 1024 / 1024).toFixed(1) + 'MB'
        } else {
            return (length / 1024).toFixed() + 'KB'
        }
    } else {
        return '0KB';
    }
}

export const _getStatusText = (Statuses, id) => {
    if (isObjectArray(Statuses)) {
        let loc = Statuses.find(l => l.value === id);
        if (loc && loc.text) {
            return loc.text;
        }
    }
    return "";
}

export const _splitCamelCase = (str) => {
    str = str.charAt(0).toUpperCase() + str.slice(1);
    return str
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/([a-zA-Z])([0-9])/g, '$1 $2');
}

// export const _getTextFromValue = (optionList, value) => {
//     if (isObjectArray(optionList)) {
//         let option = optionList.find(o => o.value === value);
//         return option ? option.text : '';
//     }
//     return "";
// }

export const _getTextFromValueList = (dataList, idList, keyName = 'value') => {
    if (dataList) {

        if (isObjectArray(idList)) {
            let result = "";
            for (let i = 0; i < idList.length; i++) {
                let item = dataList.find(item => item[keyName] === idList[i]);
                if (item && item.text) {
                    result += item.text + ", ";
                }
            }
            result = result.trim();
            result = result.slice(0, -1);
            return result;
        } else if (typeof idList === 'string') {
            if (isObjectArray(dataList)) {
                let result = dataList.find(p => p.value === idList);
                return result && result.text ? result.text : "";
            }
            return "";
        }
        return "";
    }

    return "";
}

export const isValidURL = (url) => {
   const pattern = /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i;
    return pattern.test(url);
}