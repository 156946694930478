import { createSlice, createAsyncThunk, nanoid } from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../api/client';
import { getAxiosRequestConfig } from '../../../common/common';
import { Operation } from '../../../common/constants';
import Collection from '../../../component/Controls/paginators/collection';
import { refreshCreated } from '../../../component/Controls/paginators/services/createdResource';
import { refreshRemoved } from '../../../component/Controls/paginators/services/removedResource';
import { toaster } from '../../../component/Controls/toasts/toaster';
import { isJSON } from '../../../utilities/utilityFunctions';

const initialState = {
    form: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    data: {
        totalItems: 0,
        items: [],
        status: fetchStatus.IDLE,
        error: null,
        currentPage: 0,
        isFiltered: false,
        records: new Collection(),
        firstRecords: new Collection()
    },
    single: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    creation: {
        status: fetchStatus.IDLE,
        error: null,
        createdResource: undefined
    },
    modification: {
        status: fetchStatus.IDLE,
        error: null,
        modifiedResource: undefined
    },
    removal: {
        status: fetchStatus.IDLE,
        error: null,
        removedResource: undefined
    },
    checkSubDomainIsTaken: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    show: false,
    createMode: false,
    editMode: false,
};


export const getCompanyForm = createAsyncThunk('company/getCompanyForm', async (statusModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, statusModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let form = undefined;
        if (data && isJSON(data)) {
            form = JSON.parse(data);
        }
        return {
            form: form,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getAllCompanies = createAsyncThunk('company/getAllCompanies', async (companyModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', companyModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let company;
        if (isJSON(data)) {
            company = JSON.parse(data);
        }
        return {
            page: companyModel.page,
            company: company,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getCompanyById = createAsyncThunk('company/getCompanyById', async (companyModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, companyModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let company = undefined;
        if (data && isJSON(data)) {
            company = JSON.parse(data);
        }
        return {
            page: companyModel.page,
            company: company,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const createCompany = createAsyncThunk('company/createCompany', async (companyModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, companyModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let company = undefined;
        if (data && isJSON(data)) {
            company = JSON.parse(data);
        }
        return {
            company: company,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const updateCompany = createAsyncThunk('company/updateCompany', async (companyModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, companyModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let company = undefined;
        if (data && isJSON(data)) {
            company = JSON.parse(data);
        }
        return {
            company: company,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const removeStatus = createAsyncThunk('company/removeStatus', async (companyModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, companyModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        return {
            id: data,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getCheckSubDomainIsTaken = createAsyncThunk('company/getCheckSubDomainIsTaken', async (companyModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, companyModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let company = undefined;
        if (data && isJSON(data)) {
            company = JSON.parse(data);
        }
        return {
            company: company,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});


export const companylice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        updateStatus: (state) => {
            state.data.status = fetchStatus.DONE;
        },
        updateSingleStatus: (state) => {
            state.single.status = fetchStatus.IDLE;
        },
        updateCreationStatus: (state) => {
            state.creation.status = fetchStatus.IDLE;
        },
        updateModificationStatus: (state) => {
            state.modification.status = fetchStatus.IDLE;
        },
        updateRemovalStatus: (state) => {
            state.removal.status = fetchStatus.IDLE;
        },
        updateCurrentPage: (state, page) => {
            state.data.currentPage = page.payload;
        },
        updateIsFiltered: (state) => {
            state.data.isFiltered = true;
        },
        loadSingleData: (state, _data) => {
            state.single.data = Object.assign({}, _data.payload);
        },
        updateSingleData: (state, _data) => {
            state.single.refreshed = nanoid();
            state.single.data = _data.payload ? Object.assign({}, _data.payload.company) : _data.payload;
            if (_data.payload && _data.payload.company) {
                if (_data.payload.operation === Operation.UPDATED) {
                    let _item = state.data.records.Get(state.data.currentPage);
                    if (_item && _item.records && Array.isArray(_item.records) && _item.records.length > 0) {
                        let _updatedObjectIndex = _item.records.findIndex(a => a.id === _data.payload.company.id);
                        let newRecords = _item.records;
                        let _company = {
                            id: _data.payload.company.id,
                            CompanyName: _data.payload.company.CompanyName.Data,
                            CompanyNumber: _data.payload.company.CompanyNumber.Data,
                            Status: _data.payload.company.Status.Data
                        };
                        newRecords[_updatedObjectIndex] = _company;
                        _item.records = newRecords;
                        let records = new Collection();
                        records.Add(_item.key, newRecords);
                        state.data.records.Remove(_item.key);
                        records.Concat(state.data.records);
                        state.data.records = records;
                    }
                }
            }
        },
        createNewCompany: state => {
            state.single.data = {
                "CompanyName": {
                    "Id": "txtCompanyName",
                    "Data": ""
                },
                "CompanyPhoneNumber": {
                    "Id": "txtCompanyPhoneNumber",
                    "Data": ""
                },
                "CompanyAddress": {
                    "Id": "txtCompanyAddress",
                    "Data": ""
                },
                "CompanyWebsite": {
                    "Id": "txtCompanyWebsite",
                    "Data": ""
                },
                "WorkspaceURL": {
                    "Id": "txtWorkspaceURL",
                    "Data": ""
                },
                "Status": {
                    "Id": "drpStatus",
                    "Data": ""
                },
                "Plan":{
                    "Id": "drpPlan",
                    "Data": ""
                }
            };
        },
        showCompanyFlyout: (state, action) => {
            const { createMode, editMode } = action.payload;
            state.createMode = createMode;
            state.editMode = editMode;
            state.show = true;
        },
        closeCompanyFlyout: (state, action) => {
            state.createMode = false;
            state.editMode = false;
            state.show = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllCompanies.pending, (state, action) => {
            state.data.status = fetchStatus.LOADING;
        })
        .addCase(getAllCompanies.fulfilled, (state, action) => {
            state.data.totalItems = action.payload.company.totalItems;
            state.data.items = action.payload.company.items;
            state.data.isFiltered = false;
            state.data.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        })
        .addCase(getAllCompanies.rejected, (state, action) => {
            state.data.status = fetchStatus.FAILED;
            state.data.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getCompanyById.pending, (state, action) => {
            state.single.status = fetchStatus.LOADING;
        }).addCase(getCompanyById.fulfilled, (state, action) => {
            state.single.data = action.payload.company;
            if (action.payload.page >= 0) {
                let _records = new Collection();
                _records.Add(action.payload.page, action.payload.company);
                _records.Concat(state.data.firstRecords);
                state.data.firstRecords = _records;
            }
            state.single.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getCompanyById.rejected, (state, action) => {
            state.single.status = fetchStatus.FAILED;
            state.single.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(createCompany.pending, (state, action) => {
            state.creation.status = fetchStatus.LOADING;
        }).addCase(createCompany.fulfilled, (state, action) => {
            if (action.payload.company) {
                state.single.data = action.payload.company;
                let _company = {
                    id: action.payload.company.id,
                    CompanyName: action.payload.company.CompanyName.Data,
                    CompanyPhoneNumber: action.payload.company.CompanyPhoneNumber.Data,
                    Status: action.payload.company.Status.Data
                };
                const created = refreshCreated(state.data.records, _company);
                state.data.records = created.records;
                state.data.currentPage = created.lastPage;
                state.data.totalItems += 1;
                state.single.refreshed = nanoid();
            }
            state.creation.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(createCompany.rejected, (state, action) => {
            state.creation.status = fetchStatus.FAILED;
            state.creation.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(updateCompany.pending, (state, action) => {
            state.modification.status = fetchStatus.LOADING;
        }).addCase(updateCompany.fulfilled, (state, action) => {
            state.single.data = action.payload.company;
            state.modification.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(updateCompany.rejected, (state, action) => {
            state.modification.status = fetchStatus.FAILED;
            state.modification.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(removeStatus.pending, (state, action) => {
            state.removal.status = fetchStatus.LOADING;
        }).addCase(removeStatus.fulfilled, (state, action) => {
            const removed = refreshRemoved(state.data.records, action.payload.id, state.data.currentPage);
            state.data.records = removed.records;
            state.data.currentPage = removed.lastPage;
            state.data.totalItems -= 1;
            state.single.refreshed = nanoid();
            state.removal.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(removeStatus.rejected, (state, action) => {
            state.removal.status = fetchStatus.FAILED;
            state.removal.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getCompanyForm.pending, (state, action) => {
            state.form.status = fetchStatus.LOADING;
        }).addCase(getCompanyForm.fulfilled, (state, action) => {
            state.form.data = action.payload.form;
            state.form.status = fetchStatus.SUCCEEDED;
        }).addCase(getCompanyForm.rejected, (state, action) => {
            state.form.status = fetchStatus.FAILED;
            state.form.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getCheckSubDomainIsTaken.pending, (state, action) => {
            state.checkSubDomainIsTaken.status = fetchStatus.LOADING;
        }).addCase(getCheckSubDomainIsTaken.fulfilled, (state, action) => {
            state.checkSubDomainIsTaken.data = action.payload.company;
            state.checkSubDomainIsTaken.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(getCheckSubDomainIsTaken.rejected, (state, action) => {
            state.checkSubDomainIsTaken.status = fetchStatus.FAILED;
            state.checkSubDomainIsTaken.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        });

    }
});

export const { updateCreationStatus, updateModificationStatus, updateRemovalStatus,
    updateSingleStatus, updateIsFiltered, updateStatus, loadSingleData,
    updateCurrentPage, updateSingleData, createNewCompany, showCompanyFlyout, closeCompanyFlyout } = companylice.actions;

export default companylice.reducer


export const selectAllCompanies = state => state.company.data.items;
export const selectTotalItems = state => state.company.data.totalItems;
export const selectStatus = state => state.company.data.status;

export const selectCreationStatus = state => state.company.creation.status;

export const selectModificationStatus = state => state.company.modification.status;

export const selectRemovalStatus = state => state.company.removal.status;


export const selectCompanyById = (state) => {
    return state.company.single ? state.company.single.data : undefined;
}

export const selectCompanyFormStatus = state => state.company.form.status;
export const selectCompanyFormError = state => state.company.form.error;
export const selectCompanyForm = state => state.company.form.data;

export const selectCompanyFlyoutShow = state => state.company.show;
export const selectCompanyFlyoutCreateMode = state => state.company.createMode;
export const selectCompanyFlyoutEditmode= state => state.company.editMode;

export const selectCheckSubDomainIsTakenStatus = state => state.company.checkSubDomainIsTaken.status;
export const selectCheckSubDomainIsTaken = state => state.company.checkSubDomainIsTaken.data;