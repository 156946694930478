// import { createStore } from "redux";

// import rootReducer from "./reducers";

// // const store = createStore(rootReducer);
// const store = createStore(
//     rootReducer,
//     // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );

// export default store;

import { configureStore } from "@reduxjs/toolkit";
import accountsSlice from "./features/accounts/reducerSlices/accountsSlice";
import applicantSlice from "./features/applicant/reducerSlices/applicantSlice";
import layoutReducer from "./component/layouts/reducerSlices/layoutSlice";
import jobsReducer from './features/jobOrder/reducerSlices/jobSlice';
import jobFilterReducer from "./component/Controls/filters/reducerSlices/jobFilterSlice";
import jobSorterReducer from './component/Controls/dataSorters/reducerSlices/jobSorterSlice';
import locationsReducer from './features/locations/reducerSlices/locationSlice';

import applicantSorterReducer from './component/Controls/dataSorters/reducerSlices/applicantSorterSlice';
import filterReducer from './component/Controls/filters/reducerSlices/filterSilce';
import reviewersReducer from './features/reviewer/reducerSlices/reviewerSlice';

import statusUpdateModalPopUpReducer from './component/Controls/modals/reducerSlices/statusUpdateModalPopUpSlice';
import alertModalPopUpReducer from './component/Controls/modals/reducerSlices/alertModalPopUpSlice';
import ChangeLocationModalPopUpReducer from './component/Controls/modals/reducerSlices/ChangeLocationModalPopUpSlice';
import downloadPhotoModalPopUpReducer from './component/Controls/modals/reducerSlices/downloadPhotoModalPopUpSlice';
import modalPopUpReducer from './component/Controls/modals/reducerSlices/modalPopUpSlice';
import fillOrderModalPopUpReducer from './component/Controls/modals/reducerSlices/fillOrderModalPopUpSlice';

import pageContainerReducer from "./component/Controls/paginators/paginator/reducerSlices/pageContainerSlice";
import clientsReducer from "./features/setting/reducerSlices/clientSlice";
import clientFilterReducer from "./component/Controls/filters/reducerSlices/clientFilterSlice";
import confirmAlertReducer from "./component/Controls/alerts/reducerSlices/confirmAlertSlice";

import usersReducer from "./features/setting/reducerSlices/userSlice";
import userFilterReducer from "./component/Controls/filters/reducerSlices/userFilterSlice";
import changePasswordModalPopUpReducer from "./component/Controls/modals/reducerSlices/changePasswordModalPopUpSlice";
import messagesReducer from './features/inbox/reducerSlices/messageSlice';
import messagetemplatesReducer from './features/inbox/reducerSlices/messagetemplateSlice';

import saveFilterModalPopUpReducer from './component/Controls/modals/reducerSlices/saveFilterModalPopUpSlice';
import saveJobFilterModalPopUpReducer from './component/Controls/modals/reducerSlices/saveJobFilterModalPopUpSlice';

import DNRModalPopUpReducer from './component/Controls/modals/reducerSlices/DNRModalPopUpSlice';
import PreferredClientModalPopUpReducer from "./component/Controls/modals/reducerSlices/PreferredClientModalPopUpSlice";
import chatFlyoutReducer from "./component/Flyout/reducerSlices/chatFlyoutSlice";
import dashboardReducer from './features/dashboard/reducerSlices/dashboardSlice';

import prospectsReducer from "./features/crm/reducerSlices/prospectsSlice";
import prospectsFilterReducer from "./component/Controls/filters/reducerSlices/prospectsFilterSlice";
import prospectsStatusUpdateModelPopUpReducer from "./component/Controls/modals/reducerSlices/prospectsStatusUpdateModelPopUpSlice";

import leadsReducer from "./features/crm/reducerSlices/leadSlice";
import leadFilterReducer from "./component/Controls/filters/reducerSlices/leadFilterSlice";
import leadSorterReducer from "./component/Controls/dataSorters/reducerSlices/leadSorterSlice";
import leadStatusUpdateModalPopUpReducer from "./component/Controls/modals/reducerSlices/leadStatusUpdateModelPopUpSlice"
import leadCalledFlyoutReduce from "./component/Flyout/reducerSlices/leadCalledFlyoutSlice";
import leadNoteFlyoutReduce from "./component/Flyout/reducerSlices/leadNoteFlyoutSlice";
import leadRouteNoteModalPopUpReducer from "./component/Controls/modals/reducerSlices/leadRouteNoteModalPopUpSlice";

import scheduleReducer from "./features/crm/reducerSlices/scheduleSlice";
import scheduleFilterReducer from "./component/Controls/filters/reducerSlices/scheduleFilterSlice";
import usersLocationReducer from "./features/crm/reducerSlices/usersLocationSlice";

import reportReducer from "./features/report/reducerSlices/reportSlice";
import routeTrackingReducer from "./features/crm/reducerSlices/routeTrackingSlice";
import routeTrackingFilterReducer from "./component/Controls/filters/reducerSlices/routeTrackingFilterSlice";
import routeTrackingSorterReducer from './component/Controls/dataSorters/reducerSlices/routeTrackingSorterSlice';
import routeTrackingLeadModalPopUpReducer from "./component/Controls/modals/reducerSlices/routeTrackingLeadModalPopUpSlice";
import prospectsPeopleReducer from "./features/prospectsPeople/reducerSlices/prospectsPeopleSlice";
import prospectsPeopleFilterReducer from "./component/Controls/filters/reducerSlices/prospectsPeopleFilterSlice";
import prospectsPeopleSorterReducer from './component/Controls/dataSorters/reducerSlices/prospectsPeopleSorterSlice';
import statusesReducer from "./features/setting/reducerSlices/statusesSlice";
import peopleImportModalPopUpReducer from "./component/Controls/modals/reducerSlices/peopleImportModalPopUpSlice";
import clientImportModalPopUpReducer from "./component/Controls/modals/reducerSlices/clientImportModalPopUpSlice";
import companyReducer from "./features/setting/reducerSlices/companySlice";
import experienceReducer from "./features/setting/reducerSlices/experienceSlice";
import skillGroupReducer from "./features/setting/reducerSlices/skillGroupSlice";
import positionsReducer from "./features/setting/reducerSlices/positionsSlice";

export const store = configureStore({
    reducer: {
        accounts: accountsSlice,
        applicants: applicantSlice,
        layout: layoutReducer,
        jobs: jobsReducer,
        jobFilter: jobFilterReducer,
        saveJobFilterModalPopUp: saveJobFilterModalPopUpReducer,
        jobSorter: jobSorterReducer,
        locations: locationsReducer,
        applicantSorter: applicantSorterReducer,
        filter: filterReducer,
        reviewers: reviewersReducer,
        statusUpdateModalPopUp: statusUpdateModalPopUpReducer,
        alertModalPopUp: alertModalPopUpReducer,
        ChangeLocationModalPopUp: ChangeLocationModalPopUpReducer,
        downloadPhotoModalPopUp: downloadPhotoModalPopUpReducer,
        modalPopUp: modalPopUpReducer,
        fillOrderModalPopUp: fillOrderModalPopUpReducer,
        pageContainer: pageContainerReducer,
        clients: clientsReducer,
        clientFilter: clientFilterReducer,
        confirmAlert: confirmAlertReducer,
        users: usersReducer,
        userFilter: userFilterReducer,
        changePasswordModalPopUp: changePasswordModalPopUpReducer,
        messages: messagesReducer,
        messagetemplates: messagetemplatesReducer,
        saveFilterModalPopUp: saveFilterModalPopUpReducer,
        DNRModalPopUp: DNRModalPopUpReducer,
        PreferredClientModalPopUp: PreferredClientModalPopUpReducer,
        chatFlyout: chatFlyoutReducer,
        dashboards: dashboardReducer,
        prospects: prospectsReducer,
        prospectsFilter: prospectsFilterReducer,
        prospectsStatusUpdateModelPopUp: prospectsStatusUpdateModelPopUpReducer,
        leads: leadsReducer,
        leadFilter: leadFilterReducer,
        leadSorter: leadSorterReducer,
        leadCalledFlyout: leadCalledFlyoutReduce,
        leadStatusUpdateModalPopUp: leadStatusUpdateModalPopUpReducer,
        leadNoteFlyout: leadNoteFlyoutReduce,
        leadRouteNoteModalPopUp: leadRouteNoteModalPopUpReducer,
        schedules: scheduleReducer,
        scheduleFilter: scheduleFilterReducer,
        usersLocation:usersLocationReducer,
        report:reportReducer,
        routeTracking: routeTrackingReducer,
        routeTrackingFilter: routeTrackingFilterReducer,
        routeTrackingSorter: routeTrackingSorterReducer,
        routeTrackingLeadModalPopUp:routeTrackingLeadModalPopUpReducer,
        prospectsPeople: prospectsPeopleReducer,
        prospectsPeopleFilter: prospectsPeopleFilterReducer,
        prospectsPeopleSorter: prospectsPeopleSorterReducer,
        statuses: statusesReducer,
        peopleImportModalPopUp: peopleImportModalPopUpReducer,
        clientImportModalPopUp: clientImportModalPopUpReducer,
        company: companyReducer,
        experience: experienceReducer,
        skillGroup: skillGroupReducer,
        positions: positionsReducer,
    }
});

export default store;