import { unwrapResult } from "@reduxjs/toolkit";
import { USER_KEY, Role } from "../../../common/constants";
import store from '../../../store';
import { login, refreshToken, register } from './../reducerSlices/accountsSlice';

export const accountService = {
    isAuthenticated: () => {
        return localStorage.getItem(USER_KEY) ? true : false;
    },
    getAuthenticatedUser: () => {
        let user;
        if (accountService.isAuthenticated()) {
            user = JSON.parse(localStorage.getItem(USER_KEY));
        }
        return user || { id: "" };
    },
    login: async (loginModel) => {
        const result = await store.dispatch(login(loginModel));
        const data = unwrapResult(result);
        if (data && data.id) {

        }
    },
    logout: () => {
        if (accountService.isAuthenticated()) {
            //stopRefreshTokenTimer();
            localStorage.removeItem(USER_KEY);
        }
    },
    getToken: () => {
        const user = accountService.getAuthenticatedUser();
        return user ? `Bearer fake-jwt-token ${user.jwtToken}` : "";
    },
    isAdmin: () => {
        const user = accountService.getAuthenticatedUser();
        return user && user.role === Role.ADMIN ? true : false;
    },
    isPayroll: () => {
        const user = accountService.getAuthenticatedUser();
        return user && user.role === Role.PAYROLL ? true : false;
    },
    isReviewer: () => {
        const user = accountService.getAuthenticatedUser();
        return user && user.role === Role.REVIEWER ? true : false;
    },
    isSalesRep: () => {
        const user = accountService.getAuthenticatedUser();
        if (user) {
            switch (user.email) {
                case 'thk3355@gmail.com':
                    return true;
                    break;
                default:
                // code block
            }

        }
        return user && user.role === Role.SALESREP ? true : false;
    },
    isSalesManager: () => {
        const user = accountService.getAuthenticatedUser();
        return user && user.role === Role.SALESMANAGER ? true : false;
    },
    isApplicant: () => {
        const user = accountService.getAuthenticatedUser();
        return user && user.role === Role.APPLICANT ? true : false;
    },
    register: async (registerModel) => {
        const result = await store.dispatch(register(registerModel));
        const data = unwrapResult(result);
        if (data && data.id) {
            // localStorage.setItem(USER_KEY, JSON.stringify(data));
            // const user = accountService.getAuthenticatedUser();
            //startRefreshTokenTimer(user);
        }
    },
    getLoggedInUserId: () => {
        const user = accountService.getAuthenticatedUser();
        return user && user.id ? user.id : "";
    },
    getLoggedInUserRole: () => {
        const user = accountService.getAuthenticatedUser();
        return user && user.role ? user.role : "";
    },
    getLoggedInUserLocationId: () => {
        const user = accountService.getAuthenticatedUser();
        return user && user.locationId ? user.locationId : "";
    },
    isAuthorized: () => {
        if (accountService.isAuthenticated()
            && (accountService.isAdmin() || accountService.isPayroll() || accountService.isReviewer() || accountService.isSalesRep() || accountService.isSalesManager())) {
            return true;
        }
        else {
            localStorage.removeItem(USER_KEY);
            return false;
        }

    }
    //,
    // refreshTokenAsync: async () => {
    //     if (accountService.isAuthenticated()) {
    //         const user = accountService.getAuthenticatedUser();
    //         if (user) {
    //             const result = await store.dispatch(refreshToken({ id: user.id }));
    //             const data = unwrapResult(result);
    //             if (data && data.id) {
    //                 startRefreshTokenTimer(user);
    //             }
    //         }
    //     }
    // }
}

let refreshTokenTimeout;

const startRefreshTokenTimer = (user) => {
    if (user) {
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(user.jwtToken.split('.')[1]));

        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        refreshTokenTimeout = setTimeout(accountService.refreshTokenAsync, timeout);
    }
}

const stopRefreshTokenTimer = () => {
    clearTimeout(refreshTokenTimeout);
}
